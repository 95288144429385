import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Pricing from "../components/pricing";

const PricingPage = () => {
  return (
    <Layout>
      <SEO
        keywords={[
          `spectatin pricing`,
          `tracking plan`,
          `marketing attribution`
        ]}
        title="Pricing"
      />

      <div className="mx-auto max-w-4xl py-12"><Pricing /></div>
    </Layout>
  );
}

export default PricingPage;
