import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import Button from "../components/button";
const stripePublishableKey = process.env.GATSBY_STRIPE_PUBLISHABLE_KEY;
const stripePrice = process.env.GATSBY_STRIPE_PRICE;
const stripeSuccessUrl = process.env.GATSBY_STRIPE_SUCCESS_URL;
const stripeCancelUrl = process.env.GATSBY_STRIPE_CANCEL_URL;

let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(stripePublishableKey);
  }
  return stripePromise;
};

const Checkout = () => {
  const [loading, setLoading] = useState(false);

  const redirectToCheckout = async (event) => {
    event.preventDefault();
    setLoading(true);

    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout({
      mode: "subscription",
      lineItems: [{ price: stripePrice, quantity: 1 }],
      successUrl: stripeSuccessUrl,
      cancelUrl: stripeCancelUrl,
    });

    if (error) {
      console.warn("Error:", error);
      setLoading(false);
    }
  };

  return (
    <Button disabled={loading} onClick={redirectToCheckout}>
      Sign Up
    </Button>
  );
};

export default Checkout;
