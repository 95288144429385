import React from "react";
import Button from "../components/button";
import Emoji from "../components/emoji";
import Checkout from "../components/checkout";

const MONTHLY_PRICE = 2970;

const Pricing = () => {
  const handleSignUpClick = (plan) => {
    // window.analytics.track("plan selected", {
    //   plan_name: plan,
    //   price: MONTHLY_PRICE,
    //   quantity: 1,
    // });
    window.analytics.track("onboarding progressed", {
      step: 1,
      plan_name: plan,
      price: MONTHLY_PRICE,
      quantity: 1,
    });
  };

  return (
    <>
      <div id="Pricing" className="mx-auto w-full px-4 sm:px-6 lg:px-8">
        <p className="text-base leading-6 text-indigo-600 font-semibold tracking-wide uppercase">
          Pricing
        </p>
        <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
          I help you model attribution
        </h3>
        <div className="flex flex-col md:flex-row px-auto justify-around items-center">
          <div className="flex-col p-4 rounded shadow-lg border border-indigo-600 mt-4 h-full w-full md:w-auto">
            <div className="pb-4 md:w-56 border-b flex flex-col justify-center items-center">
              <p className="text-base leading-6 text-indigo-600 font-semibold tracking-wide uppercase">
                Just a plan
              </p>
              <div className="flex items-center ">
                <span className="flex text-xl leading-8 tracking-tight text-gray-900 sm:text-2xl sm:leading-10">
                  $
                </span>
                <span className="text-4xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-5xl sm:leading-10 pr-2">
                  4750
                </span>
                {/* <span className="flex text-xl leading-7 text-gray-500 lg:mx-auto">
                  /month
                </span> */}
              </div>
            </div>
            <div className="flex flex-col items-center pt-4">
              <div className="text-xl leading-7 text-gray-700 lg:mx-auto">
                <Emoji
                  className="text-green-500"
                  label="checkmark"
                  symbol="0x2713"
                />{" "}
                1 Tracking plan
              </div>
            </div>
            <div className="flex justify-center items-center mt-4">
              <a href="/contact/">
                <Button
                  onClick={() => handleSignUpClick("just_a_plan")}
                  isSecondary={true}
                >
                  Contact
                </Button>
              </a>
            </div>
          </div>
          <div className="flex-col p-4 rounded shadow-2xl border-2 border-indigo-600 mt-4 h-full w-full md:w-auto">
            <div className="pb-4 md:w-64 border-b flex flex-col justify-center items-center">
              <p className="text-base leading-6 text-indigo-600 font-semibold tracking-wide uppercase">
                Monthly
              </p>
              <div className="flex items-center ">
                <span className="flex text-xl leading-8 tracking-tight text-gray-900 sm:text-2xl sm:leading-10">
                  $
                </span>
                <span className="text-4xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-5xl sm:leading-10 pr-2">
                  {MONTHLY_PRICE}
                </span>
                <span className="flex text-xl leading-7 text-gray-500 lg:mx-auto">
                  /month
                </span>
              </div>
            </div>
            <div className="flex flex-col items-center pt-4">
              <div className="text-xl leading-7 text-gray-700 lg:mx-auto">
                <Emoji
                  className="text-green-500"
                  label="checkmark"
                  symbol="0x2713"
                />{" "}
                1 Tracking plan
              </div>
              <div className="text-xl leading-7 text-gray-700 lg:mx-auto">
                <Emoji
                  label="checkmark"
                  className="text-green-500"
                  symbol="0x2713"
                />{" "}
                5 Tracking plan updates
              </div>
            </div>
            <div className="flex justify-center items-center pt-4">
              <a onClick={() => handleSignUpClick("monthly")}>
                <Checkout />
              </a>
            </div>
          </div>{" "}
          <div className="flex-col p-4 rounded shadow-lg border border-indigo-600 mt-4 h-full w-full md:w-auto">
            <div className="pb-4 md:w-56 border-b flex flex-col justify-center items-center">
              <p className="text-base leading-6 text-indigo-600 font-semibold tracking-wide uppercase">
                Business
              </p>
              <div className="flex items-center ">
                <span className="flex text-xl leading-7 text-gray-500 lg:mx-auto">
                  Custom
                </span>
              </div>
            </div>
            <div className="flex flex-col items-center pt-4">
              <div className="text-xl leading-7 text-gray-700 lg:mx-auto">
                <Emoji
                  className="text-green-500"
                  label="checkmark"
                  symbol="0x2713"
                />{" "}
                2+ Tracking plans
              </div>
              <div className="text-xl leading-7 text-gray-700 lg:mx-auto">
                <Emoji
                  className="text-green-500"
                  label="checkmark"
                  symbol="0x2713"
                />{" "}
                6+ Tracking plan updates
              </div>
            </div>
            <div className="flex justify-center items-center pt-4">
              <a href="/contact/">
                <Button
                  onClick={() => handleSignUpClick("business")}
                  isSecondary={true}
                >
                  Contact
                </Button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pricing;
