import React from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line react/display-name
const Emoji = React.memo(({ className, label, symbol }) => {
  return (
    <span className={className} role="img" aria-label={label}>
        {String.fromCodePoint(symbol)}
    </span>)

})

Emoji.propTypes = {
  className: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  symbol: PropTypes.string.isRequired
}


export default Emoji